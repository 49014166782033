<template>
  <div class="mr-1" style="position: relative">
    <b-button class="bg-white h-100" @click="isShow = !isShow" id="button--filter">
      <b-img :src="require('@/assets/images/icons/filter.svg')" />
      <span>Filter</span>
    </b-button>
    <Transition name="fade" mode="out-in">
      <div v-if="isShow" class="bg-white content__filter" :style="$route.name == 'report.index' ? 'left:unset;transform:unset' : ''
        ">
        <div class="d-flex justify-content-between align-items-center py-1">
          <div class="px-1 custom__dropdown--item" role="button" @click="isShow = false" id="button--close__filter">
            <feather-icon size="16" class="text-dark fw-bold-800" style="margin-right: 5px" icon="ArrowLeftIcon" />
            <span class="text-dark fw-bold-700 size14"> Filter Data </span>
          </div>
          <b-button class="mr-1" @click="submitFilter" id="button--submit__filter">
            Terapkan
          </b-button>
        </div>
        <hr />
        <b-form-checkbox v-if="!is_actual" v-model="checkTanggal" class="custom-control-primary m-1">
          Tanggal
        </b-form-checkbox>
        <b-collapse id="tanggal" class="p-1" style="background-color: #f6f7fa" :visible="checkTanggal">
          <div class="d-flex flex-column">
            <b-form-group>
              <v-select v-model="selected" label="title" :options="options" :reduce="(option) => option.label"
                class="border border-8" />
            </b-form-group>
            <div class="d-flex justify-content-between">
              <b-input-group class="input-group-merge">
                <b-form-input v-if="selected == 'last_day'" v-model="rangeDate" placeholder="3" type="number" />
                <flat-pickr v-else v-model="rangeDate" class="form-control" :config="{
                  mode: selected === 'daterange' ? 'range' : 'single',
                }" />
                <b-input-group-append is-text>
                  <b-img v-if="selected !== 'last_day'" :src="require('@/assets/images/icons/calendar.svg')"
                    alt="dateIcon" />
                  <span v-else>Hari</span>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </b-collapse>
        <hr v-if="!is_transaction" />
        <b-form-checkbox v-if="!is_transaction" v-model="checkStatus" class="custom-control-primary m-1">
          Status
        </b-form-checkbox>
        <b-collapse v-if="isRetur" id="status" class="p-1" style="background-color: #f6f7fa" :visible="checkStatus">
          <div class="d-flex flex-column">
            <b-form-group>
              <b-form-checkbox v-model="filter.status" value="success" class="custom-control-primary">
                Sukses
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="filter.status" value="pending" class="custom-control-primary">
                Pending
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="filter.status" value="rejected" class="custom-control-primary">
                Rejected
              </b-form-checkbox>
            </b-form-group>
          </div>
          <div class="d-flex flex-column" v-if="isOrder">
            <b-form-group>
              <b-form-checkbox v-model="filter.status" value="success" class="custom-control-primary">
                Sukses
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="filter.status" value="pending" class="custom-control-primary">
                Pending
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="filter.status" value="retur" class="custom-control-primary">
                Retur
              </b-form-checkbox>
            </b-form-group>
          </div>
          <div class="d-flex flex-column" v-if="isOpname">
            <b-form-group>
              <b-form-checkbox v-model="filter.status" value="approved" class="custom-control-primary">
                Approved
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="filter.status" value="pending" class="custom-control-primary">
                Pending
              </b-form-checkbox>
            </b-form-group>
          </div>
          <!-- <div class="d-flex flex-column" v-if="isActual">
            <b-form-group>
              <b-form-checkbox
                v-model="filter.product_type"
                value="all"
                class="custom-control-primary"
              >
                Semua
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="filter.product_type"
                value="parent"
                class="custom-control-primary"
              >
                Induk
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="filter.product_type"
                value="retail"
                class="custom-control-primary"
              >
                Eceran
              </b-form-checkbox>
            </b-form-group>
          </div> -->
        </b-collapse>
        <!-- Filter Metode Pembayaran -->
        <hr v-if="is_transaction && !is_actual" />
        <b-form-checkbox v-if="is_transaction && !is_actual" v-model="checkStatus" class="custom-control-primary m-1">
          Metode Pembayaran
        </b-form-checkbox>
        <b-collapse id="status" class="p-1" style="background-color: #f6f7fa" :visible="checkStatus"
          v-if="!isRetur && !isOpname">
          <div class="d-flex flex-column" v-if="is_transaction && !isPayoutOther">
            <b-form-group>
              <b-form-checkbox v-model="filter.transactionMethod" :value="'cash'" class="custom-control-primary">
                Tunai
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="filter.transactionMethod" :value="'card'" class="custom-control-primary">
                Kartu Debit/Kredit
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="filter.transactionMethod" :value="'transfer'" class="custom-control-primary">
                Transfer
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="filter.transactionMethod" :value="'giro'" class="custom-control-primary">
                Giro
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="filter.transactionMethod" :value="'wallet'" class="custom-control-primary">
                Deposit
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="filter.transactionMethod" :value="'piutang'" class="custom-control-primary">
                Piutang
              </b-form-checkbox>
            </b-form-group>
          </div>
          <div class="d-flex flex-column" v-else>
            <b-form-group>
              <b-form-checkbox v-model="filter.paymentMethod" value="cash" class="custom-control-primary">
                Tunai
              </b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="!isPayoutOther">
              <b-form-checkbox v-model="filter.paymentMethod" value="card" class="custom-control-primary">
                Kartu Debit/Kredit
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="filter.paymentMethod" value="transfer" class="custom-control-primary">
                Transfer
              </b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="!is_stock">
              <b-form-checkbox v-model="filter.paymentMethod" value="giro" class="custom-control-primary">
                Giro
              </b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="!is_stock && !isPayoutOther">
              <b-form-checkbox v-model="filter.paymentMethod" value="wallet" class="custom-control-primary">
                Deposit
              </b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="!is_stock && !isPayoutOther">
              <b-form-checkbox v-model="filter.paymentMethod" value="piutang" class="custom-control-primary">
                Piutang
              </b-form-checkbox>
            </b-form-group>
          </div>
        </b-collapse>
        <b-form-checkbox v-if="is_actual" v-model="checkProduct" class="custom-control-primary m-1">
          Tipe Produk
        </b-form-checkbox>
        <b-collapse id="status" class="p-1" style="background-color: #f6f7fa" :visible="checkProduct">
          <div class="d-flex flex-column">
            <b-form-group>
              <b-form-checkbox v-model="filter.product_type" value="parent" class="custom-control-primary">
                Produk Induk
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="filter.product_type" value="retail" class="custom-control-primary">
                Produk Eceran
              </b-form-checkbox>
            </b-form-group>
          </div>
        </b-collapse>
        <hr v-if="!is_transaction && !isOpname && !isRetur" />
        <b-form-checkbox v-if="!is_transaction && !isOpname && !isRetur" v-model="pelanggan.is_show"
          class="custom-control-primary m-1">
          Tipe Pelanggan
        </b-form-checkbox>
        <b-collapse id="TipePelanggan" class="p-1" style="background-color: #f6f7fa" :visible="pelanggan.is_show">
          <b-form-group class="mb-0">
            <v-select v-model="pelanggan.checkboxValue" label="title" :options="pelanggan.options"
              class="border border-8" />
          </b-form-group>
        </b-collapse>
      </div>
    </Transition>
  </div>
</template>

<script>
import {
  BImg,
  BButton,
  BCollapse,
  VBToggle,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  components: {
    BImg,
    BButton,
    BCollapse,
    BFormCheckbox,
    vSelect,
    BFormGroup,
    BFormInput,
    flatPickr,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    styleName: {
      type: String,
      default: "",
    },
    isOpname: {
      type: Boolean,
    },
    isOrder: {
      type: Boolean,
    },
    isRetur: {
      type: Boolean,
    },
    is_actual: {
      type: Boolean,
      default: false,
    },
    isPayoutOther: {
      type: Boolean,
      default: false,
    },
    is_transaction: {
      type: Boolean,
      default: false,
    },
    is_stock: {
      type: Boolean,
      default: false,
    },
    getData: {
      type: Function,
    },
  },
  data() {
    return {
      selected: "",
      options: [
        {
          label: "last_day",
          title: "Paling Terakhir",
        },
        {
          label: "date",
          title: "Tanggal",
        },
        {
          label: "daterange",
          title: "Rentang Tanggal ",
        },
        {
          label: "before_date",
          title: "Sebelum Tanggal",
        },
      ],
      selectedHari: "Hari",
      optionsDay: ["Hari"],
      checkTanggal: false,
      checkStatus: false,
      checkProduct: false,
      pelanggan: {
        is_show: false,
        options: ["Ritel", "Non-Ritel"],
        checkboxValue: "Ritel",
      },
      rangeDate: null,
      isShow: false,
      filter: {
        transactionMethod: [],
        paymentMethod: null,
        // product_type: "",
        filter_date_type: "",
        filter_date_value: "",
        per_page: 10,
        payment_method: null,
        transaction_method: [],
      },
    };
  },
  watch: {
    selected(value) {
      this.filter.filter_date_type = value;
      if (
        this.$route.path === "/transaksi" ||
        this.$route.path === "/penyetoran"
      ) {
        localStorage.setItem("typeDate", value);
      }
    },
    // checkTanggal(value) {
    //   if (value === false) {
    //     this.filter.filter_date_type = ''
    //     this.filter.filter_date_value = ''
    //   }
    // },
    // checkStatus(value) {
    //   if (value === false) {
    //     this.filter.product_type = ''
    //   }
    // },
    rangeDate(value) {
      if (
        this.$route.path === "/transaksi" ||
        this.$route.path === "/penyetoran"
      ) {
        localStorage.setItem("checkTypeDate", value);
      }
    },
    checkTanggal: {
      handler(value) {
        if (
          this.$route.path === "/transaksi" ||
          this.$route.path === "/penyetoran"
        ) {
          if (value === true) {
            localStorage.setItem("checkDate", true);
          } else {
            localStorage.setItem("checkDate", false);
            this.selected = null;
            this.rangeDate = null;
          }
        }
      },
    },
    checkStatus: {
      handler(value) {
        if (this.$route.path === "/penyetoran") {
          if (value === true) {
            localStorage.setItem("checkStatus", true);
          } else {
            localStorage.setItem("checkStatus", false);
            localStorage.setItem("checkPaymentMethod", null);
            this.filter.paymentMethod = null;
          }
        }
        if (this.$route.path === "/transaksi") {
          if (value === true) {
            localStorage.setItem("checkStatus", true);
          } else {
            localStorage.setItem("checkStatus", false);
            localStorage.setItem("checkTransactionMethod", null);
            this.filter.transactionMethod = [];
          }
        }
      },
    },
    "filter.paymentMethod": {
      handler(value) {
        if (this.$route.path === "/penyetoran") {
          localStorage.setItem("checkPaymentMethod", value);
        }
      },
    },
    "filter.transactionMethod": {
      handler(value) {
        if (this.$route.path === "/transaksi") {
          localStorage.setItem("checkTransactionMethod", value);
        }
      },
    },
  },
  created() {
    if (
      this.$route.path === "/transaksi" ||
      this.$route.path === "/penyetoran"
    ) {
      if (this.$route.query.date) {
        let dateQuery = this.$route.query.date;
        localStorage.setItem("typeDate", "date");
        localStorage.setItem("checkDate", true);
        localStorage.setItem("checkTypeDate", dateQuery);
        this.selected = localStorage.getItem("typeDate");
        this.checkStatus = localStorage.getItem("checkDate");
      }
      localStorage.getItem("checkDate") === "true"
        ? (this.checkTanggal = true)
        : (this.checkTanggal = false);
      localStorage.getItem("checkStatus") === "true"
        ? (this.checkStatus = true)
        : (this.checkStatus = false);
      this.filter.paymentMethod = localStorage.getItem("checkPaymentMethod");
      const storedMethod = localStorage.getItem("checkTransactionMethod");
      if (storedMethod) {
        try {
          this.filter.transactionMethod = JSON.parse(storedMethod);
        } catch (e) {
          // Handle cases where the stored data is not valid JSON
          this.filter.transactionMethod = storedMethod.split(",");
        }
      } else {
        this.filter.transactionMethod = [];
      }
      this.selected = localStorage.getItem("typeDate");
      this.rangeDate = localStorage.getItem("checkTypeDate");
      if (
        (this.checkStatus && this.filter.paymentMethod !== null) ||
        (this.checkTanggal && this.selected && this.rangeDate)
      ) {
        this.submitFilter();
      }
      if (
        (this.checkStatus &&
          Array.isArray(this.filter.transactionMethod) &&
          this.filter.transactionMethod.length > 0) ||
        (this.checkTanggal && this.selected && this.rangeDate)
      ) {
        this.submitFilter();
      }
    } else {
      localStorage.removeItem("checkPaymentMethod");
      localStorage.removeItem("checkTransactionMethod");
      localStorage.removeItem("checkStatus");
      localStorage.removeItem("typeDate");
      localStorage.removeItem("checkDate");
    }
  },
  methods: {
    // inputStatus() {
    //   if (this.checkStatus === true) {
    //     localStorage.setItem("checkStatus", true);
    //   } else {
    //     localStorage.setItem("checkStatus", false);
    //   }
    // },
    submitFilter() {
      this.filter.payment_method = this.filter.paymentMethod;
      this.filter.transaction_method = this.filter.transactionMethod;
      if (this.selected && this.checkTanggal === true) {
        this.filter.filter_date_type = this.selected;
        this.filter.filter_date_value =
          this.selected === "daterange"
            ? this.rangeDate.toString().replace(/\s/g, "").replace("to", "~")
            : this.rangeDate;
      }
      if (this.selected && this.checkTanggal === false) {
        this.filter.filter_date_type = "";
        this.filter.filter_date_value = "";
      }
      if (this.checkStatus === false) {
        this.filter.payment_method = "";
        this.filter.transaction_method = [];
      }
      this.filter.payment_method = !this.filter.payment_method
        ? null
        : this.filter.payment_method;

      this.filter.transaction_method = !this.filter.transaction_method
        ? []
        : this.filter.transaction_method;

      if (this.checkProduct === false) {
        this.filter.product_type = "";
      }
      this.filter.product_type = !this.filter.product_type
        ? null
        : this.filter.product_type;
      this.$emit("filterBase", this.filter);
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.content__filter {
  position: absolute;
  z-index: 999;
  width: 311px;
  left: -50%;
  transform: translateX(-50%);
  border-radius: 16px;
  margin-top: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.input-group {
  input {
    background: #f1f2f5;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: none;
  }

  .input-group-append {
    .input-group-text {
      background-color: #f1f2f5;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: none;
    }
  }
}
</style>
